import { EventBarrier } from "@/components/common/event-barrier";
import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
} from "@/components/ui/embedded-toolbar";
import { useToggleUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { selectIsLabelsBeingCreated } from "@/store/point-cloud-analysis-tool-selector";
import {
  PointCloudAnalysis,
  removeAnalysis,
  setAnalysisVisibility,
  setIsLabelsBeingCreated,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  AddLabelIcon,
  ANNOTATION_ZINDEX_RANGE_MAP,
  DeleteIcon,
  neutral,
  NonVisibleIcon,
  PointCloudAnalysisColormapIcon,
  PointCloudAnalysisReferencePlaneIcon,
  useOverrideCursor,
} from "@faro-lotv/app-component-toolbox";
import { Box, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { MEASURE_ANIMATION_LENGTH } from "../measurements/measure-constants";
import { ColormapOptionsPanel } from "./colormap-options-panel";
import { ColormapReferencePlanePanel } from "./colormap-reference-plane-panel";

type AnalysisActionBarProp = {
  /** 3D point to anchor the html component to. */
  anchorPoint: Vector3;

  /** Active analysis object to be modified. */
  analysis: PointCloudAnalysis;
};

enum AnalysisOptionPanel {
  ColorScale = "colorScale",
  ReferencePlane = "referencePlane",
}

/**
 * @returns Action toolbar for modify active analysis.
 */
export function AnalysisActionBar({
  anchorPoint,
  analysis,
}: AnalysisActionBarProp): JSX.Element | null {
  const parentRef = useViewOverlayRef();

  const buttonSize = "36px";
  const actionBarPointOffset = "2px";

  const [activePanel, setActivePanel] = useState<AnalysisOptionPanel>();

  const { unitOfMeasure, toggleUnitOfMeasure } = useToggleUnitOfMeasure(false);

  const dispatch = useAppDispatch();
  const deleteActiveAnalysis = useCallback(() => {
    setActivePanel(undefined);
    dispatch(removeAnalysis(analysis.id));
    dispatch(setIsLabelsBeingCreated(false));
  }, [analysis.id, dispatch]);

  const isLabelsBeingCreated = useAppSelector(selectIsLabelsBeingCreated);
  const hideAnalysis = useCallback(() => {
    dispatch(
      setAnalysisVisibility({
        analysisId: analysis.id,
        visible: false,
      }),
    );
    dispatch(setIsLabelsBeingCreated(false));
  }, [analysis.id, dispatch]);

  const addLabels = useCallback(() => {
    dispatch(setIsLabelsBeingCreated(!isLabelsBeingCreated));
  }, [dispatch, isLabelsBeingCreated]);

  const changeActivePanel = useCallback(
    (panel: AnalysisOptionPanel) => {
      setActivePanel(activePanel === panel ? undefined : panel);
    },
    [activePanel],
  );

  useOverrideCursor("crosshair", isLabelsBeingCreated);

  return (
    <>
      <AppAwareHtml
        portal={parentRef}
        position={anchorPoint}
        zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
        eps={-1}
        style={{
          transform: `translate(-${actionBarPointOffset}, calc(-100% - ${actionBarPointOffset}))`,
        }}
      >
        <EmbeddedToolbar
          isActive
          sx={{
            transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
            padding: "0px",
            backgroundColor: neutral[999],
            borderRadius: "6px",
          }}
        >
          <Tooltip title="Change color scale" placement="top">
            <EmbeddedToolbarButton
              aria-label="change color scale"
              buttonSize={buttonSize}
              value="Change color scale"
              selected={activePanel === AnalysisOptionPanel.ColorScale}
              onClick={() => changeActivePanel(AnalysisOptionPanel.ColorScale)}
            >
              <PointCloudAnalysisColormapIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Change reference plane" placement="top">
            <EmbeddedToolbarButton
              aria-label="change reference plane"
              buttonSize={buttonSize}
              value="Change reference plane"
              selected={activePanel === AnalysisOptionPanel.ReferencePlane}
              onClick={() =>
                changeActivePanel(AnalysisOptionPanel.ReferencePlane)
              }
            >
              <PointCloudAnalysisReferencePlaneIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Add labels" placement="top">
            <EmbeddedToolbarButton
              aria-label="add labels"
              buttonSize={buttonSize}
              value="Add labels"
              selected={isLabelsBeingCreated}
              onClick={addLabels}
            >
              <AddLabelIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Hide analysis" placement="top">
            <EmbeddedToolbarButton
              aria-label="hide analysis"
              buttonSize={buttonSize}
              value="Hide analysis"
              onClick={hideAnalysis}
            >
              <NonVisibleIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Change units" placement="top">
            <EmbeddedToolbarButton
              aria-label="change unit"
              buttonSize={buttonSize}
              value="Change Unit"
              onClick={toggleUnitOfMeasure}
            >
              <Typography>{unitOfMeasure === "metric" ? "m" : "ft"}</Typography>
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <EmbeddedToolbarButton
              aria-label="delete analysis"
              buttonSize={buttonSize}
              value="Delete"
              onClick={deleteActiveAnalysis}
            >
              <DeleteIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
        </EmbeddedToolbar>
      </AppAwareHtml>
      {activePanel && (
        <AppAwareHtml
          portal={parentRef}
          position={anchorPoint}
          zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
          eps={-1}
          style={{
            transform: `translate(-${actionBarPointOffset}, +${actionBarPointOffset})`,
          }}
        >
          <EventBarrier>
            <Box
              component="div"
              padding={1}
              sx={{
                borderRadius: 1,
                backgroundColor: neutral[999],
              }}
            >
              {activePanel === AnalysisOptionPanel.ColorScale && (
                <ColormapOptionsPanel
                  analysis={analysis}
                  unitOfMeasure={unitOfMeasure}
                />
              )}
              {activePanel === AnalysisOptionPanel.ReferencePlane && (
                <ColormapReferencePlanePanel
                  analysis={analysis}
                  unitOfMeasure={unitOfMeasure}
                />
              )}
            </Box>
          </EventBarrier>
        </AppAwareHtml>
      )}
    </>
  );
}
